<template>
  <div>
    <!--<div style="display: flex;margin-top: 90%">-->
    <!--  <el-input v-model="tel" placeholder="请输入手机号" />-->
    <!--  <el-button type="primary" @click="yz_tel()">验证</el-button>-->
    <!--</div>-->
  </div>
</template>

<script>
import {Encrypt} from "@/utils/secret";
import {ElMessage} from "element-plus";

export default {
  name: "yz_tel",
  data(){
    return{
      tel: ''
    }
  },
  created() {
    const time = Date.parse(new Date()) + this.uuid();
    const url = window.location.href;
    const cs = url.split('?')[1];
    const jtcs = cs.split("&");
    const code = jtcs[0].split("=")[1];
    this.axios.post('/wxSendMessage/yzOpenid', (response) => {
      if (response.code === 200){
        this.$router.push({
          name: 'Mobile_show_food',
          params: {
            key: time
          }
        });
      }
    },{
      code: code,
      message_type: 0
    })
  },
  methods:{
    uuid() {
      var s = [];
      var hexDigits = '0123456789abcdef';
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = '4';
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = '-';

      this.uuidA = s.join('');
      return this.uuidA;
    },
  }
}
</script>

<style scoped>

</style>